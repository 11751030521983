<template>
  <div>
    <vs-navbar shadow-scroll fixed center-collapsed not-line>
      <template #left>
        <vs-button flat icon style="margin-right: 20px" class="menu-btn" @click="activeSidebar = !activeSidebar">
          <i class='bx bx-menu'></i>
        </vs-button>
        <router-link to="/" class="navbar-link">
          <img
            src="https://zhishi.oss-cn-beijing.aliyuncs.com/user_projects_assets/00e1c3c0813d734b6c8db826bbb0de3a.svg">
        </router-link>
      </template>
      <vs-navbar-item v-for="(link, index) in links" :key="index" :active="active.name === link.name"
        :to="active.name === link.name ? null : link.url">
        {{ link.name }}
      </vs-navbar-item>
      <vs-navbar-item>
        <vs-button @click="changeGoto()">
          小猫派对
        </vs-button>
      </vs-navbar-item>
      <vs-navbar-item>
        <vs-button @click="changeGotodev()">
          devHost
        </vs-button>
      </vs-navbar-item>
      <vs-navbar-item>
        <vs-button @click="changeGotoTXC()">
          反馈平台
        </vs-button>
      </vs-navbar-item>
      <template #right>
        <vs-navbar-item>
          <vs-switch @click="changeTheme()">
            <template #on>
              <i class='bx bxs-sun'></i>
            </template>
            <template #off>
              <i class='bx bxs-moon'></i>
            </template>
          </vs-switch>
        </vs-navbar-item>
      </template>
    </vs-navbar>
    <vs-sidebar v-model="activeItem" :open.sync="activeSidebar">
      <template #logo>
        <img src="https://zhishi.oss-cn-beijing.aliyuncs.com/user_projects_assets/00e1c3c0813d734b6c8db826bbb0de3a.svg">
      </template>
      <vs-sidebar-item v-for="(link, index) in links" :key="index" :to="link.url" :id="link.name">
        {{ link.name }}
      </vs-sidebar-item>
    </vs-sidebar>
    <div style="margin-bottom: 100px"></div>
  </div>
</template>

<script>

let Config = null
try {
  Config = require('@/../posts/data/config.json')
} catch (e) {
  Config = require('@/defaults/config.json')
}



export default {
  name: 'Navbar',
  data: () => {
    return {
      config: Config.config,
      activeItem: 'Home',
      activeSidebar: false,
      dark: false,
      links: [
        {
          name: 'Home',
          url: '/'
        },
        {
          name: 'Blog',
          url: '/posts'
        },
        {
          name: 'Projects',
          url: '/projects'
        },
        {
          name: 'Links',
          url: '/links'
        },
        {
          name: 'Tags',
          url: '/tags'
        },
        {
          name: 'About',
          url: '/about'
        },
        {
          url: '/events',
          name: "Dev&Events(开发版路径&活动页面)"
        },
        {
          url: '/login',
          name: "登录",
        }
      ]
    }
  },
  methods: {
    changeTheme: function () {
      if (this.dark) {
        document.getElementsByTagName('body')[0].setAttribute('vs-theme', 'light');
        document.documentElement.removeAttribute('theme-mode');
        this.dark = false
      } else {
        document.getElementsByTagName('body')[0].setAttribute('vs-theme', 'dark');
        document.documentElement.setAttribute('theme-mode', 'dark');
        this.dark = true
      }
    },
    changeGoto: function () {
      window.open('https://www.ccw.site/detail/6335bf3432719f3d2652f3b7?a=website', '_blank')
      console.error('用户点击官网操作', GotoWebSite)


    },
    changeGotodev: function () {
      window.open('http://1.181.226.238:8080/', '_blank')
      console.error('用户点击官网操作', GotoDev)


    },
    changeGotoTXC: function () {
      let productId = 454953; // 1221为腾讯问卷公众号的产品 id
      /* 请注意url单词是product而不是products，products是旧版本的参数，用错地址将不能成功提交 */
      window.location = 'https://support.qq.com/product/' + productId
    }
  },
  computed: {
    active: function () {
      const cur = this.$route.path
      var ret = {
        name: null,
        url: null
      }
      for (var i = 0; i < this.links.length; i++) {
        if (this.links[i].url === cur) ret = this.links[i]
      }
      return ret
    }
  }
}
</script>
