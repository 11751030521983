<template>
    <div id="app">
        <a>活动</a>
        <vs-button @click="changeGoto()">
          开发版地址（不定期开放，未开放属于YUEN不在线）
        </vs-button>
        <div>我还兼职新组件测试</div>
        <div>
            <t-button>按钮</t-button>
        </div>
        <t-space>
            <t-link theme="default" :prefix-icon="renderPrefixIcon" :onClick="clickLink"> 跳转链接 </t-link>
            <t-link theme="primary" underline href="https://tdesign.tencent.com/" target="_self">
                <link-icon slot="prefix-icon"></link-icon>
                跳转链接
            </t-link>
            <t-link theme="danger" hover="color" href="https://tdesign.tencent.com/" target="_blank" @click="clickLink">
                <jump-icon slot="suffixIcon" />
                跳转链接
            </t-link>
            <t-link theme="warning" :suffixIcon="renderSuffixIcon" disabled> 跳转链接 </t-link>
        </t-space>
    </div>
</template>

<script>
import { LinkIcon, JumpIcon } from 'tdesign-icons-vue';

export default {
    components: {
        LinkIcon,
        JumpIcon,
    },
    data() {
        return {
            renderPrefixIcon: () => <LinkIcon />,
            renderSuffixIcon: () => <JumpIcon />,
        };
    },
    methods: {
        clickLink(e) {
            console.log('触发', e);
            this.username = this.$route.query.name;
        },
    },
};</script>