<template>
  <div id="app" class="content">
    <h5>此版本：1.0.0</h5>
    <Navbar />
    <router-view />
    <br v-for="index in 10" :key="index" />
    <div class="wrapper">
      <Footer />
    </div>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'App',
  components: {
    Navbar,
    Footer
  }
}
</script>

<style>
.w-full {
  width: 100% !important;
}

.w-full .vs-card__text {
  width: 100%;
}
body::-webkit-scrollbar {
    display: none;
}
body {
    -ms-overflow-style: none;
}
html {
    overflow: -moz-hidden-unscrollable; /*注意！若只打 hidden，chrome 的其它 hidden 会出问题*/
    height: 100%;
}

body {
	height: 100%;
	width: calc(100vw + 18px); /*浏览器滚动条的长度大约是 18px*/
	overflow: auto;
}
</style>
