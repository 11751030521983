<template>
    <div class="bg">
      <div id="register">
          <h2>注册页面</h2>
          <el-form ref="form" :model="form" label-width="20%">
          <el-form-item label="用户名:">
              <el-input v-model="form.username"></el-input>
          </el-form-item>
          <el-form-item label="密  码:">
              <el-input v-model="form.password" type="password"></el-input>
          </el-form-item>
          </el-form>
          <el-button type="primary" round @click="register" class="btn">注册</el-button>
      </div>
    </div>
  </template>
  
  <script>
  import axios from "axios"
  export default {
    data () {
      return {
        form: {
          username: '',
          password: ''  
        },
        isnull: false
      };
    },
   
    methods: {
      register() {
        if(this.form.username == '') {
          this.$message.error('用户名不能为空');
        }else if(this.form.password == '') {
          this.$message.error('密码不能为空');
        }else{
          axios.post('http://127.0.0.1/register', {
            params: {
              name: this.form.username,
              password: this.form.password
            }
          }).then(res => {
            // console.log(res.data.message);
            if(res.data.status == 200) {
              this.$alert('是否返回登录页面', '注册成功', {
                confirmButtonText: '确定',
                callback: action => {
                  this.$router.push('/login')
                }
              })
            }else if(res.data.status == 202) {
              this.$alert('用户名已存在', '注册失败', {
                confirmButtonText: '确定',
                callback: action => {
                  this.form.username = '',
                  this.form.password = ''
                }
              })
            }else{
              console.log(res.message);
            }
          }).catch(err => {
            console.log('操作失败' + err);
          })
        }
      }
    }
  }
  </script>
  