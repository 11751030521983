<template>
    <div>
        <button style="background-color: white;" class="vs-pagination__arrow prev" onclick=go()><h3>点我前往</h3></button>
    </div>
</template>

<script>

go = function () {
    window.open('https://www.ccw.site/detail/6335bf3432719f3d2652f3b7?a=website', '_blank')
    console.error('用户点击官网操作', GotoWebSite)


}
</script>