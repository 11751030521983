<template>
    <div class="bg">
      <div id="login">
          <h2>登录页面</h2>
          <el-form ref="form" :model="form" label-width="20%">
          <el-form-item label="用户名:">
              <el-input v-model="form.username"></el-input>
          </el-form-item>
          <el-form-item label="密  码:">
              <el-input v-model="form.password" type="password"></el-input>
          </el-form-item>
          </el-form>
          <el-button type="primary" round @click="login" class="btn">登录</el-button>
      </div>
    </div>
  </template>
  
  <script>
  import axios from "axios"
  export default {
    data () {
      return {
        form: {
          username: '',
          password: ''  
        }
      };
    },
  
    methods: {
      login() {
        if(this.form.username == '') {
          this.$message.error('用户名不能为空');
        }else if(this.form.password == '') {
          this.$message.error('密码不能为空');
        }else{
          axios.get('http://127.0.0.1/login', {
            params: {
              name: this.form.username,
              password: this.form.password
            }
          }).then(res=>{
            if(res.data.status == 200) {
              this.$router.push({
                path: '/home',
                query: {
                  name: this.form.username
                }
              })
            }else{
              this.$alert('用户名或密码错误', '登录失败', {
                confirmButtonText: '确定',
                callback: action => {
                  this.form.username = '',
                  this.form.password = ''
                }
              });
            }
          }).catch(err=>{
              console.log("登录失败" + err);
          })
        }
      },
      register() {
        this.$router.push('/register')
      }
    }
  }
  </script>
  