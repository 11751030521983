import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Vuesax from 'vuesax'
import * as Sentry from "@sentry/vue"
import { BrowserTracing } from "@sentry/tracing"
import 'vuesax/dist/vuesax.css'
import 'boxicons'
import 'boxicons/css/boxicons.min.css'
import TDesign from 'tdesign-vue';
// 引入组件库全局样式资源
import 'tdesign-vue/es/style/index.css';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';



Vue.use(TDesign);
Vue.use(ElementUI);

console.log(`

“小猫派对！”

欢迎向酷可工作室投递简历                 v1.0.0

Powered by %c小猫派对在线站%cv1.0.0%c

YUEN-SITE`,
    'background-color: #1A55ED; padding: 7px; color: #fff;',
    'background-color: #FCBF23; color: #000; padding: 7px;',
    'font-weight: bold')

Vue.config.productionTip = false

Vue.use(Vuesax, {})

Vue.mixin({
    methods: {
        changeTitle: function(title) {
            let Config = null
            try {
                Config = require('@/../posts/data/config.json')
            } catch (e) {
                Config = require('@/defaults/config.json')
            }
            document.title = `${title} - ${Config.config.blogTitle}`
        },
        getConfig: function(name = 'config.json') {
            let Config = null
            try {
                Config = require(`@/../posts/data/${name}`)
            } catch (e) {
                Config = require(`@/defaults/${name}`)
            }
            return Config
        }
    },
    computed: {
        tags: function() {
            let Posts = null
            try {
                Posts = require('@/../posts/data/posts.json')
            } catch (e) {
                Posts = require('@/defaults/posts.json')
            }
            const posts = Posts.posts
            const tags = []
            for (var i = 0; i < posts.length; i++) {
                for (var j = 0; j < posts[i].tags.length; j++) {
                    let index = -1
                    for (var k = 0; k < tags.length; k++) {
                        if (tags[k].name === posts[i].tags[j]) {
                            index = k
                            break
                        }
                    }
                    if (index === -1) {
                        tags.push({
                            name: posts[i].tags[j],
                            posts: [
                                posts[i]
                            ]
                        })
                    } else {
                        tags[index].posts.push(posts[i])
                    }
                }
            }
            return tags
        }
    }
})

router.afterEach((to, from, next) => {
    window.scrollTo(0, 0)
})
Sentry.init({
    Vue,
    dsn: "https://a4bafe1e2e384634b6517d0addca342e@o1187333.ingest.sentry.io/4504109972520960",
    release: 'personal-blog@0.1.1',
    integrations: [
        new BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            tracingOrigins: ["localhost", "yuenteam.github.io", "scatparty.github.io", /^\//],
        }),
    ],
    Errorlog: true,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    release: require('../package.json').version
});

new Vue({
    router,
    render: h => h(App)
}).$mount('#app')